import axios from "@services/axios";

export const subscribeUser = async (): Promise<PushSubscription | null> => {
    if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
        console.error("Push messaging is not supported");
        return null;
    }

    const registration = await navigator.serviceWorker.ready;

    try {
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
                "BAhHhJ00ycWk6hyCgFwgbOWhrjihL1evr9OnYJsh_P_wCMrBw5HlvTOzgT4anJkwAj5mJITGsRvkhGvWS1WU26U"
            ),
        });
        console.log("User is subscribed:", subscription);

        await axios.post("/subscribe", subscription);

        return subscription;
    } catch (error) {
        console.error("Failed to subscribe the user:", error);
        return null;
    }
};

// Helper function to convert VAPID key to the right format
const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
