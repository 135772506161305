import Modal from "@components/Modal";
import { Box, Button, Link, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";

import VerticalNumberRange from "./VerticalNumberRange";

export default function PreTimeModal(props: {
    showPrepTimeModal: boolean;
    setshowPrepTimeModal: any;
    onConfirm: () => void;
    prepTime: number;
    setPrepTime: any;
}) {
    const {
        showPrepTimeModal,
        setshowPrepTimeModal,
        onConfirm,
        prepTime,
        setPrepTime,
    } = props;
    return (
        <Modal
            title={<strong> When wil this order be ready?</strong>}
            open={showPrepTimeModal}
            closeModal={() => {
                setshowPrepTimeModal(false);
            }}
            maxWidth="md"
            fullScreen={false}
            content={
                <>
                    <Box
                        sx={{
                            width: "100%",
                            mt: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                mx: 3,
                                textAlign: "center",
                                color: "grey",
                            }}
                        >
                            {
                                "Prep time determine when we send a Driver and updates customer's deleivery ETA."
                            }
                            <Link href="#" underline="always">
                                {'What is suggested prep time?"'}
                            </Link>
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                            <VerticalNumberRange
                                minValue={1}
                                maxValue={60}
                                value={prepTime}
                                setValue={setPrepTime}
                                rangeText={"mins"}
                                rightCationText="Suggested prep time"
                                leftCationText={`Ready at ${moment()
                                    .add(prepTime, "minutes")
                                    .format("h:mm A")}`}
                            />
                        </Box>
                        <Button
                            sx={{ width: "100%" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onConfirm();
                                setshowPrepTimeModal(false);
                            }}
                        >
                            Confirm Order
                        </Button>
                    </Box>
                </>
            }
        />
    );
}
