import Header from "@components/Header";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Button, Grid2, IconButton, Typography } from "@mui/material";
import { useCommonStore } from "@store/index";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import IssueWithOrderModal from "./IssueWithOrderModal";
import KitchenStatusesModal from "./KitchenStatusesModal";
import NoOrder from "./NoOrder";
import OrderCard from "./OrderCard";
import PreTimeModal from "./PrepTimeModal";

export type KitchenStatus = {
    title: string;
    description: string;
    color: string;
};

export type OrderItemType = {
    name: string;
    quantity: number;
    seletedMenuName: string;
    price: number;
};

export type OrderType = {
    id: string;
    orderNumber: string;
    orderStatus: string;
    prepTime?: string;
    orderTime: string;
    deliveryDate: string;
    customerName: string;
    restaurantName: string;
    items: OrderItemType[];
};

export type IssueWithOrderActionType = {
    title: string;
    action: () => void;
};

export default function ActiveOrders() {
    const navigate = useNavigate();

    const [orderingStatus, setOrderingStatus] = useState<string>("Accepting");

    const [showKitchenStatusModal, setshowKitchenStatusModal] =
        useState<boolean>(false);

    const [showPrepTimeModal, setshowPrepTimeModal] = useState<boolean>(false);
    const [prepTime, setPrepTime] = useState<number>(15);

    const [showIssueWithOrderModal, setshowIssueWithOrderModal] =
        useState<boolean>(false);
    const setSelectedNavItem = useCommonStore(
        (state) => state.setSelectedNavItem
    );

    const [orders, setOrders] = useState<OrderType[]>([
        {
            id: "1",
            orderNumber: "#ac123",
            orderStatus: "NEW",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2022-10-12",
            customerName: "John Doe",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 15,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chiken Burger",
                    quantity: 2,
                    price: 16,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Pizza",
                    quantity: 3,
                    price: 17,
                    seletedMenuName: "Fast Food",
                },

                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 3,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "2",
            orderNumber: "#ac124",
            orderStatus: "NEW",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2022-10-12",
            customerName: "John Doe",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "3",
            orderNumber: "#ac125",
            orderStatus: "NEW",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2022-10-12",
            customerName: "John Doe",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 2,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 3,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "4",
            orderNumber: "#ac126",
            orderStatus: "NEW",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2022-10-12",
            customerName: "John Doe",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 2,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
    ]);
    const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(null);

    const [kitchenStatuses, setKitchenStatuses] = useState<KitchenStatus[]>([
        {
            title: "Accepting",
            description: "Business as usual",
            color: "green",
        },
        {
            title: "Busy",
            description: "Need more prep time",
            color: "orange",
        },

        {
            title: "Paused",
            description: "Pause now orders",
            color: "red",
        },
    ]);

    const [issueWithOrderActions, setIssueWithOrderActions] = useState<
        IssueWithOrderActionType[]
    >([
        {
            title: "Adjust Prep Time",
            action: () => setshowPrepTimeModal(true),
        },
        {
            title: "Make Something as out of Stock",
            action: () => navigate("/manage-menu"),
        },
        {
            title: "Support Live Chat",
            action: () => navigate("/help-and-support"),
        },
        {
            title: "Cancel Order",
            action: () => alert("Cancel Order"),
        },
    ]);

    const ConfirmPrepTime = (defaultPrepTime?: number) => {
        const updateOrder: any = {
            ...selectedOrder,
            orderStatus: "IN PROGRESS",
            prepTime: defaultPrepTime || prepTime,
        };
        setSelectedOrder(updateOrder);
        setOrders(
            orders?.map((order) => {
                if (order.id === updateOrder.id) {
                    return updateOrder;
                }
                return order;
            })
        );
        setshowPrepTimeModal(false);
    };

    const [totalPrice, setTotalPrice] = useState<number>(0);
    useEffect(() => {
        const price: any = selectedOrder?.items?.reduce((acc, item) => {
            return acc + item.price;
        }, 0);
        setTotalPrice(price);
    }, [selectedOrder]);

    const onClickOrderHistoryBtn = () => {
        setSelectedNavItem({ title: "Order History" });
        navigate("/order-history");
    };

    return (
        <>
            <Grid2 container spacing={0}>
                <Grid2 size={4}>
                    <Header
                        bgColor={
                            (orderingStatus === "Paused" && "red") ||
                            (orderingStatus === "Busy" && "orange") ||
                            "transparent"
                        }
                    >
                        <Typography
                            component="div"
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography variant="h6" fontWeight={"bold"}>
                                Orders
                            </Typography>

                            <Button
                                sx={{
                                    py: "6px",
                                    px: 1,
                                    borderRadius: "4px",
                                    border: "1px solid lightgrey",
                                    mr: 2,
                                    bgcolor: "#fff",
                                }}
                                onClick={() => {
                                    setshowKitchenStatusModal(true);
                                }}
                            >
                                <Typography
                                    sx={{
                                        borderLeft: `5px solid ${
                                            (orderingStatus === "Accepting" &&
                                                "green") ||
                                            (orderingStatus === "Busy" &&
                                                "orange") ||
                                            (orderingStatus === "Paused" &&
                                                "red") ||
                                            "#fff"
                                        }`,
                                        paddingInline: 1.5,
                                        fontSize: 12,
                                        color: "#000",
                                    }}
                                >
                                    {orderingStatus}
                                </Typography>
                            </Button>
                        </Typography>
                    </Header>
                    <Box
                        width={"100%"}
                        sx={{ borderBottom: "4px solid lightgrey" }}
                    ></Box>
                    {orders.length === 0 ? (
                        <Grid2
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 2,
                            }}
                            size={12}
                            container
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mt: 8,
                                }}
                            >
                                Currently no new orders
                            </Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "lightgrey",
                                    borderRadius: "99999px",
                                    color: "#000",
                                    fontWeight: "bold",
                                    paddingInline: 5,
                                }}
                                onClick={onClickOrderHistoryBtn}
                            >
                                See History
                            </Button>
                        </Grid2>
                    ) : (
                        <Grid2 container width={"100%"}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mt: 2,
                                    ml: 3,
                                    mb: 1,
                                }}
                            >
                                NEED ACTIONS
                            </Typography>
                            {orders?.map((order, i) => (
                                <OrderCard
                                    selectedOrder={selectedOrder}
                                    order={order}
                                    key={i}
                                    onOrderClick={() => setSelectedOrder(order)}
                                />
                            ))}
                            <Button
                                variant="contained"
                                sx={{
                                    background: "lightgrey",
                                    borderRadius: "99999px",
                                    color: "#000",
                                    fontWeight: "bold",
                                    paddingInline: 5,
                                    mx: "auto",
                                    mt: 3,
                                }}
                                onClick={onClickOrderHistoryBtn}
                            >
                                See History
                            </Button>
                        </Grid2>
                    )}
                </Grid2>
                {selectedOrder?.id ? (
                    <Grid2 size={8} bgcolor={"#fff"}>
                        <Box
                            sx={{
                                display: "flex",
                                px: 2,
                                py: "12px",
                                height: "65px",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {selectedOrder?.restaurantName}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: "#bebebe",
                                    }}
                                >
                                    {selectedOrder?.orderNumber} .{" "}
                                    {selectedOrder?.items?.length} items
                                </Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{
                                        py: "6px",
                                        borderRadius: "4px",
                                        border: "2px solid lightgrey",
                                        bgcolor: "#fff",
                                        width: "40px",
                                    }}
                                >
                                    <PrintIcon fontSize="inherit" />
                                </IconButton>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        py: "6px",
                                        borderRadius: "4px",
                                        border: "2px solid lightgrey",
                                        bgcolor: "#fff",
                                    }}
                                    onClick={() => {
                                        setshowIssueWithOrderModal(true);
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: "#000",
                                        }}
                                    >
                                        Issue with Order
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            width={"100%"}
                            sx={{ borderBottom: "2px solid lightgrey" }}
                        ></Box>
                        <Box>
                            <Box
                                sx={{
                                    px: 2,
                                }}
                                width={"100%"}
                            >
                                <Typography
                                    component="div"
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"start"}
                                    alignItems={"start"}
                                    sx={{
                                        borderBottom: "1px solid lightgrey",
                                        width: "100%",
                                        py: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            textAlign: "start",
                                        }}
                                    >
                                        New Order
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        }}
                                    >
                                        Keep in mind
                                    </Typography>
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        borderBottom: "1px solid lightgrey",
                                        width: "100%",
                                        py: 2,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: "lightgrey",
                                            borderRadius: "99999px",
                                            color: "#000",
                                            fontWeight: "bold",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            paddingInline: 2,
                                            fontSize: 12,
                                        }}
                                        disabled
                                    >
                                        <PostAddIcon /> Text Order
                                    </Button>
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    {selectedOrder?.items?.map((item, i) => {
                                        return (
                                            <Typography
                                                key={i}
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    color: "#000",
                                                    width: "100%",
                                                    borderBottom:
                                                        "1px solid lightgrey",
                                                    py: 1,
                                                    px: 1,
                                                    bgcolor: "#fff",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                        color: "grey",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {item.seletedMenuName}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent:
                                                            "space-between",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                            color: "#000",
                                                        }}
                                                        key={i}
                                                    >
                                                        {item.quantity}X{" "}
                                                        <span
                                                            style={{
                                                                color: "grey",
                                                            }}
                                                        >
                                                            --
                                                        </span>{" "}
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight:
                                                                "normal",
                                                            color: "#000",
                                                        }}
                                                        key={i}
                                                    >
                                                        ${item.price}
                                                    </Typography>
                                                </Box>
                                            </Typography>
                                        );
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography
                            component="div"
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Grid2
                                container
                                width={"100%"}
                                spacing={2}
                                px={3}
                                py={2}
                                sx={{ borderTop: "1px solid lightgrey" }}
                            >
                                <Grid2
                                    size={12}
                                    bgcolor={"#fff"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    py={2}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "60%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: "#000",
                                            }}
                                        >
                                            {selectedOrder?.items?.length} total
                                            items
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: "#000",
                                            }}
                                        >
                                            Subtotal:
                                        </Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: "#000",
                                        }}
                                    >
                                        ${totalPrice}
                                    </Typography>
                                </Grid2>
                                {selectedOrder?.orderStatus === "NEW" ? (
                                    <>
                                        <Grid2 size={8} bgcolor={"#fff"}>
                                            <Button
                                                sx={{ width: "100%" }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    ConfirmPrepTime(21);
                                                }}
                                            >
                                                Confirm with 21 min prep time
                                            </Button>
                                        </Grid2>
                                        <Grid2 size={4} bgcolor={"#fff"}>
                                            <Button
                                                sx={{
                                                    width: "100%",
                                                    background: "lightgrey",
                                                    color: "#000",
                                                }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    setshowPrepTimeModal(true);
                                                }}
                                            >
                                                Manual prep time
                                            </Button>
                                        </Grid2>
                                    </>
                                ) : (
                                    <Button
                                        sx={{ width: "100%" }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            ConfirmPrepTime(21);
                                        }}
                                    >
                                        Order ready for pickup
                                    </Button>
                                )}
                            </Grid2>
                        </Typography>
                    </Grid2>
                ) : (
                    <Grid2 size={8} bgcolor={"#fff"}>
                        <NoOrder />
                    </Grid2>
                )}
            </Grid2>
            {/* Kitchen status modal */}
            <KitchenStatusesModal
                showKitchenStatusModal={showKitchenStatusModal}
                setshowKitchenStatusModal={setshowKitchenStatusModal}
                kitchenStatuses={kitchenStatuses}
                setOrderingStatus={setOrderingStatus}
                orderingStatus={orderingStatus}
            />

            {/* Prep time modal */}
            <PreTimeModal
                showPrepTimeModal={showPrepTimeModal}
                setshowPrepTimeModal={setshowPrepTimeModal}
                prepTime={prepTime}
                setPrepTime={setPrepTime}
                onConfirm={() => {
                    ConfirmPrepTime();
                }}
            />
            {/* Issue with order modal */}
            <IssueWithOrderModal
                showIssueWithOrderModal={showIssueWithOrderModal}
                setShowIssueWithOrderModal={setshowIssueWithOrderModal}
                issueWithOrderActions={issueWithOrderActions}
            />
        </>
    );
}
