import Logo from "@assets/logo.png";
import {
    AccessTime,
    Help,
    QueryBuilder,
    RestaurantMenu,
    Settings,
    ViewList,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar as MUIAppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { useCommonStore } from "@store/index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export interface NavItemType {
    title: string;
    link: string;
    icon: JSX.Element;
}

const sidebarMenu: NavItemType[] = [
    {
        title: "Active Orders",
        link: "/active-orders",
        icon: <ViewList />,
    },
    {
        title: "Order History",
        link: "/order-history",
        icon: <AccessTime />,
    },
    {
        title: "Manage Menu",
        link: "/manage-menu",
        icon: <RestaurantMenu />,
    },
    {
        title: "Store Hours",
        link: "/store-hours",
        icon: <QueryBuilder />,
    },
    {
        title: "Settings",
        link: "/settings",
        icon: <Settings />,
    },
    {
        title: "Help & support",
        link: "/help-support",
        icon: <Help />,
    },
];

export default function AppBar({
    headingComp,
    actionComp,
    selectedItem,
}: {
    headingComp?: React.ReactNode;
    actionComp?: React.ReactNode;
    selectedItem: Partial<NavItemType>;
}) {
    const [open, setOpen] = useState<boolean>(false);
    const theme = useTheme();
    const navigate = useNavigate();

    const setSelectedNavItem = useCommonStore(
        (state) => state.setSelectedNavItem
    );

    const onClickListItem = (item: NavItemType) => {
        setSelectedNavItem(item);
        setOpen(false);
        navigate(item.link);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation">
            <List>
                <ListItem
                    sx={{
                        background: "#000",
                        marginTop: "-10px",
                        height: "150px",
                        display: "block",
                        p: 3,
                        color: "#fff",
                        mb: 3,
                    }}
                >
                    <img src={Logo} width="40px" />
                    <Typography>Shakir Afridi</Typography>
                    <Typography sx={{ textTransform: "uppercase" }}>
                        Test Store
                    </Typography>
                </ListItem>
                {sidebarMenu.map((item) => (
                    <ListItem
                        key={item.title}
                        disablePadding
                        sx={{
                            color:
                                selectedItem.title === item.title
                                    ? theme.palette.primary.dark
                                    : "",
                        }}
                        onClick={() => onClickListItem(item)}
                    >
                        <ListItemButton>
                            <ListItemIcon
                                sx={{
                                    color:
                                        selectedItem.title === item.title
                                            ? theme.palette.primary.dark
                                            : "",
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{ flexGrow: 1, mb: 2 }}>
                <MUIAppBar
                    position="static"
                    sx={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                    }}
                >
                    <Toolbar style={{ position: "absolute", top: 0 }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </MUIAppBar>

                <Drawer open={open} onClose={() => setOpen(false)}>
                    {DrawerList}
                </Drawer>
            </Box>
        </>
    );
}
