import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";

export default function CustomTextfield(props: TextFieldProps) {
    return (
        <TextField
            {...props}
            sx={{
                ...props.sx,
                mb: 1,
                mt: 1,
            }}
            variant={props.variant || "filled"}
        />
    );
}
