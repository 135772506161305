const config: any = {
	dev: {
		apiBaseUrl: "http://localhost:8080",
	},
	uat: {
		apiBaseUrl: "https://uat.url.com",
	},
	prod: {
		apiBaseUrl: "https://api.gobig.app",
	},
};

export default config[process.env.REACT_APP_ENV?.trim() || "dev"];
