import Modal from "@components/Modal";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from "@mui/icons-material/Call";
import {
    Box,
    Button,
    Divider,
    IconButton,
    Link,
    Typography,
} from "@mui/material";
import React from "react";

import { IssueWithOrderActionType } from ".";

export default function IssueWithOrderModal(props: {
    showIssueWithOrderModal: boolean;
    setShowIssueWithOrderModal: any;
    issueWithOrderActions: IssueWithOrderActionType[];
}) {
    const {
        showIssueWithOrderModal,
        setShowIssueWithOrderModal,
        issueWithOrderActions,
    } = props;

    const onClickPhoneIcon = () => {
        const anchorTag = document.createElement("a");
        anchorTag.href = "tel:+1234567890";
        anchorTag.click();
    };

    return (
        <Modal
            title={<strong> Issue with Order</strong>}
            open={showIssueWithOrderModal}
            closeModal={() => {
                setShowIssueWithOrderModal(false);
            }}
            maxWidth="md"
            fullScreen={false}
            content={
                <>
                    <Box
                        sx={{
                            width: "100%",
                            mt: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                bgcolor: "#EDF2F3",
                                p: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                }}
                            >
                                Contacts
                                <Box
                                    sx={{
                                        bgcolor: "#fff",
                                        p: 2,
                                        width: "300px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                color: "grey",
                                            }}
                                        >
                                            Customer
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Test
                                        </Typography>
                                    </Typography>
                                    <IconButton
                                        sx={{
                                            color: "#000",
                                            bgcolor: "lightgrey",
                                        }}
                                        aria-label="add to shopping cart"
                                        onClick={() => onClickPhoneIcon()}
                                    >
                                        <CallIcon />
                                    </IconButton>
                                </Box>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontWeight: "bold",
                                    textAlign: "end",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    24/7 Support English 888-888-8888
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Spanish 888-888-8888
                                </Typography>
                            </Typography>
                        </Box>
                        {issueWithOrderActions?.map((action, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Button
                                        sx={{
                                            width: "100%",
                                            background: "#fff",
                                            boxShadow: "none",
                                            color: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            py: 3,
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            action.action();
                                            setShowIssueWithOrderModal(false);
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                color: "#000",
                                            }}
                                        >
                                            {action.title}{" "}
                                            {action.title ===
                                                "Cancel Order" && (
                                                <span
                                                    style={{
                                                        color: "orange",
                                                        paddingLeft: "100px",
                                                    }}
                                                >
                                                    Not available after order
                                                    confirmation
                                                </span>
                                            )}
                                        </Typography>
                                        <ArrowForwardIosIcon
                                            sx={{
                                                fontSize: 20,
                                                color: "grey",
                                            }}
                                        />
                                    </Button>
                                    {i < issueWithOrderActions.length - 1 && (
                                        <Divider
                                            variant="middle"
                                            sx={{ width: "100%" }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Box>
                </>
            }
        />
    );
}
