import Header from "@components/Header";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PrintIcon from "@mui/icons-material/Print";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Box, Button, Grid2, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import IssueWithOrderModal from "./IssueWithOrderModal";
import OrderCard from "./OrderCard";

export type KitchenStatus = {
    title: string;
    description: string;
    color: string;
};

export type OrderItemType = {
    name: string;
    quantity: number;
    seletedMenuName: string;
    price: number;
};

export type OrderType = {
    id: string;
    orderNumber: string;
    orderStatus: string;
    prepTime?: string;
    orderTime: string;
    deliveryDate: string;
    customerName: string;
    restaurantName: string;
    items: OrderItemType[];
};

export type IssueWithOrderActionType = {
    title: string;
    action: () => void;
};

export default function OrderHistory() {
    const navigate = useNavigate();

    const [orderingStatus, setOrderingStatus] = useState<string>("Accepting");

    const [showKitchenStatusModal, setshowKitchenStatusModal] =
        useState<boolean>(false);

    const [showPrepTimeModal, setshowPrepTimeModal] = useState<boolean>(false);

    const [showIssueWithOrderModal, setshowIssueWithOrderModal] =
        useState<boolean>(false);

    const [orders, setOrders] = useState<OrderType[]>([
        {
            id: "1",
            orderNumber: "#ac123",
            orderStatus: "IN PROGRESS",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2024-09-14T14:00:00Z",
            customerName: "John Doe",
            prepTime: "15",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 15,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chiken Burger",
                    quantity: 2,
                    price: 16,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Pizza",
                    quantity: 3,
                    price: 17,
                    seletedMenuName: "Fast Food",
                },

                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 3,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Salad",
                    quantity: 1,
                    price: 18,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "2",
            orderNumber: "#ac124",
            orderStatus: "IN PROGRESS",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2024-09-15T15:15:00Z",
            customerName: "John Doe",
            prepTime: "12",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "3",
            orderNumber: "#ac125",
            orderStatus: "IN PROGRESS",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2024-09-16T10:00:00Z",
            customerName: "John Doe",
            prepTime: "10",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 2,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 3,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
        {
            id: "4",
            orderNumber: "#ac126",
            orderStatus: "IN PROGRESS",
            orderTime: "2022-10-12T12:00:00.000Z",
            deliveryDate: "2024-09-17T12:30:00Z",
            customerName: "John Doe",
            prepTime: "5",
            restaurantName: "Shakir",
            items: [
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 1,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
                {
                    name: "Chicken Burger",
                    quantity: 2,
                    price: 10,
                    seletedMenuName: "Fast Food",
                },
            ],
        },
    ]);

    const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(null);

    const [issueWithOrderActions, setIssueWithOrderActions] = useState<
        IssueWithOrderActionType[]
    >([
        {
            title: "Adjust Prep Time",
            action: () => setshowPrepTimeModal(true),
        },
        {
            title: "Make Something as out of Stock",
            action: () => navigate("/manage-menu"),
        },
        {
            title: "Support Live Chat",
            action: () => navigate("/help-and-support"),
        },
        {
            title: "Cancel Order",
            action: () => alert("Cancel Order"),
        },
    ]);

    const [totalPrice, setTotalPrice] = useState<number>(0);
    useEffect(() => {
        const price: any = selectedOrder?.items?.reduce((acc, item) => {
            return acc + item.price;
        }, 0);
        setTotalPrice(price);
    }, [selectedOrder]);

    return (
        <>
            <Grid2 container spacing={0}>
                <Grid2 size={4} sx={{ height: "100dvh" }}>
                    <Header
                        bgColor={
                            (orderingStatus === "Paused" && "red") ||
                            (orderingStatus === "Busy" && "orange") ||
                            "transparent"
                        }
                    >
                        <Typography
                            component="div"
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Typography variant="h6" fontWeight={"bold"}>
                                History
                            </Typography>
                        </Typography>
                    </Header>
                    <Box
                        width={"100%"}
                        sx={{ borderBottom: "4px solid lightgrey" }}
                    ></Box>
                    {orders.length === 0 ? (
                        <Grid2
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 2,
                            }}
                            size={12}
                            container
                        >
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mt: 8,
                                }}
                            >
                                Currently no orders
                            </Typography>
                        </Grid2>
                    ) : (
                        <Grid2 container width={"100%"}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    mt: 2,
                                    ml: 3,
                                    mb: 1,
                                }}
                            >
                                Out for Delivery
                            </Typography>
                            {orders?.map((order, i) => (
                                <OrderCard
                                    selectedOrder={selectedOrder}
                                    order={order}
                                    key={i}
                                    onOrderClick={() => setSelectedOrder(order)}
                                />
                            ))}
                        </Grid2>
                    )}
                </Grid2>
                {selectedOrder?.id ? (
                    <Grid2 size={8} bgcolor={"#fff"} sx={{ height: "100dvh" }}>
                        <Box
                            sx={{
                                display: "flex",
                                px: 2,
                                py: "12px",
                                height: "65px",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {selectedOrder?.restaurantName}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: "#bebebe",
                                    }}
                                >
                                    {selectedOrder?.orderNumber} .{" "}
                                    {selectedOrder?.items?.length} items
                                </Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <IconButton
                                    aria-label="delete"
                                    size="small"
                                    sx={{
                                        py: "6px",
                                        borderRadius: "4px",
                                        border: "2px solid lightgrey",
                                        bgcolor: "#fff",
                                        width: "40px",
                                    }}
                                >
                                    <PrintIcon fontSize="inherit" />
                                </IconButton>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        py: "6px",
                                        borderRadius: "4px",
                                        border: "2px solid lightgrey",
                                        bgcolor: "#fff",
                                    }}
                                    onClick={() => {
                                        setshowIssueWithOrderModal(true);
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: "#000",
                                        }}
                                    >
                                        Issue with Order
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Box
                            width={"100%"}
                            sx={{ borderBottom: "2px solid lightgrey" }}
                        ></Box>
                        <Box>
                            <Box
                                sx={{
                                    px: 2,
                                }}
                                width={"100%"}
                            >
                                <Typography
                                    component="div"
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"start"}
                                    alignItems={"start"}
                                    sx={{
                                        borderBottom: "1px solid lightgrey",
                                        width: "100%",
                                        py: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            textAlign: "start",
                                        }}
                                    >
                                        Out For Delivery
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: "#a8a8a8",
                                        }}
                                    >
                                        {moment(
                                            selectedOrder?.deliveryDate
                                        ).format("[Delivering by] h:mm A, ddd")}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Typography component={"div"}>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    textAlign: "start",
                                                    color: "#a8a8a8",
                                                }}
                                            >
                                                Assigned Driver
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                    textAlign: "start",
                                                }}
                                            >
                                                Driver Name
                                            </Typography>
                                        </Typography>
                                        <Box display={"flex"} gap={1}>
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                sx={{
                                                    py: "8px",
                                                    borderRadius: "4px",
                                                    border: "2px solid lightgrey",
                                                    bgcolor: "#fff",
                                                    width: "40px",
                                                }}
                                            >
                                                <PhoneInTalkIcon fontSize="inherit" />
                                            </IconButton>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    py: "8px",
                                                    borderRadius: "4px",
                                                    border: "2px solid lightgrey",
                                                    bgcolor: "#fff",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                        color: "#000",
                                                    }}
                                                >
                                                    Driver Feedback
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    {selectedOrder?.items?.map((item, i) => {
                                        return (
                                            <Typography
                                                key={i}
                                                sx={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    color: "#000",
                                                    width: "100%",
                                                    borderBottom:
                                                        "1px solid lightgrey",
                                                    py: 1,
                                                    px: 1,
                                                    bgcolor: "#fff",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                        color: "grey",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {item.seletedMenuName}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent:
                                                            "space-between",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                            color: "#000",
                                                        }}
                                                        key={i}
                                                    >
                                                        {item.quantity}X{" "}
                                                        <span
                                                            style={{
                                                                color: "grey",
                                                            }}
                                                        >
                                                            --
                                                        </span>{" "}
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight:
                                                                "normal",
                                                            color: "#000",
                                                        }}
                                                        key={i}
                                                    >
                                                        ${item.price}
                                                    </Typography>
                                                </Box>
                                            </Typography>
                                        );
                                    })}
                                </Typography>
                            </Box>
                        </Box>
                        <Typography
                            component="div"
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Grid2
                                container
                                width={"100%"}
                                spacing={2}
                                px={3}
                                py={2}
                                sx={{ borderTop: "1px solid lightgrey" }}
                            >
                                <Grid2
                                    size={12}
                                    bgcolor={"#fff"}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    py={2}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "60%",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: "#000",
                                            }}
                                        >
                                            {selectedOrder?.items?.length} total
                                            items
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: "#000",
                                            }}
                                        >
                                            Subtotal:
                                        </Typography>
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: "#000",
                                        }}
                                    >
                                        ${totalPrice}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                        </Typography>
                    </Grid2>
                ) : (
                    <Grid2 size={8} bgcolor={"#fff"}>
                        <Typography
                            component="div"
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            height={"100dvh"}
                        >
                            {/* Can be replaced with a actual asset */}
                            <Box
                                sx={{
                                    borderBottom: "3px solid lightgrey",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 5,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ShoppingBasketIcon
                                        sx={{
                                            fontSize: 120,
                                            color: "lightgrey",
                                            position: "absolute",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            background: "#000",
                                            zIndex: 3,
                                            display: "flex",
                                            marginTop: "30px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: 9999,
                                        }}
                                    >
                                        <FavoriteIcon
                                            sx={{
                                                fontSize: 30,
                                                color: "lightgrey",
                                                zIndex: 3,
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        mt: 3,
                                    }}
                                >
                                    No Active Orders
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: "#a8a8a8",
                                        mt: 2,
                                        paddingInline: 6,
                                    }}
                                >
                                    Checkot see if your tablet is working
                                    properly by checking atest order on the
                                    Settings page
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: 2,
                                    p: 5,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        mt: 3,
                                    }}
                                >
                                    Prevent order issues from happening
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: "#a8a8a8",
                                        paddingInline: 6,
                                    }}
                                >
                                    Pay attention to the item with the tag below
                                    to reduce commonly reported customer issue
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        background: "#FF000033",
                                        borderRadius: "99999px",
                                        color: "#000",
                                        fontWeight: "bold",
                                        paddingInline: 5,
                                    }}
                                >
                                    <HomeRepairServiceIcon
                                        sx={{
                                            fontSize: 30,
                                            color: "red",
                                            marginRight: 1,
                                        }}
                                    />{" "}
                                    Double check this item Often Missing
                                </Button>
                            </Box>
                        </Typography>
                    </Grid2>
                )}
            </Grid2>

            {/* Issue with order modal */}
            <IssueWithOrderModal
                showIssueWithOrderModal={showIssueWithOrderModal}
                setShowIssueWithOrderModal={setshowIssueWithOrderModal}
                issueWithOrderActions={issueWithOrderActions}
            />
        </>
    );
}
