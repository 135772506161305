import { Grid2, Typography } from "@mui/material";
import moment from "moment";

import { OrderType } from ".";

export default function OrderCard(props: {
    selectedOrder: OrderType | null;
    order: OrderType;
    onOrderClick: () => void;
}) {
    const { selectedOrder, order, onOrderClick } = props;
    const orderTime = moment(order.orderTime).fromNow();
    return (
        <Grid2
            sx={{
                borderLeft: `5px solid ${
                    selectedOrder?.id === order?.id ? "#5f73c7" : "#fff"
                }`,
                px: 1,
                py: 2,
                borderBottom: `1px solid #edf2f3`,
                cursor: "pointer",
            }}
            onClick={() => onOrderClick()}
            container
            width={"100%"}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
            bgcolor={"#fff"}
        >
            <Grid2 size={8} display={"flex"} flexDirection={"column"}>
                <Typography
                    sx={{
                        fontSize: 14,
                        fontWeight: "bold",
                    }}
                >
                    {order?.restaurantName}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#bebebe",
                    }}
                >
                    {order?.orderNumber} . {order?.items?.length} items
                </Typography>
            </Grid2>
            <Grid2 size={4}>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        textAlign: "end",
                    }}
                >
                    {order?.orderStatus?.toUpperCase()}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: "bold",
                        color: "#bebebe",
                        textAlign: "end",
                    }}
                >
                    {orderTime}
                </Typography>
            </Grid2>
        </Grid2>
    );
}
