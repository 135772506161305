import { Grid2, Typography, useTheme } from "@mui/material";
import type { ReactNode } from "react";

export default function AuthLayout(props: { children: ReactNode }) {
    const theme = useTheme();
    return (
        <Typography component="div" sx={{ marginTop: "10%" }}>
            <Grid2
                container
                spacing={2}
                justifyContent="center"
                alignContent="center"
                textAlign="center"
                sx={{
                    m: 1,
                }}
            >
                <Grid2
                    size={{
                        xs: 12,
                        sm: 4,
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            color: theme.palette.secondary.light,
                            fontWeight: "bold",
                        }}
                    >
                        GoBig
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                spacing={2}
                justifyContent="center"
                alignContent="center"
                sx={{
                    m: 1,
                }}
            >
                <Grid2 size={{ xs: 12, sm: 4 }}>{props.children}</Grid2>
            </Grid2>
        </Typography>
    );
}
