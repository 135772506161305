import type { StateCreator } from "zustand";

type snackBarDataType = {
    message: string;
    open: boolean;
    type: "success" | "error" | "info" | "warning";
};

type NavItem = {
    title: string;
};
export interface CommonSlice {
    componentHeading: string;
    setComponentHeading: (text: string) => void;
    snackBarData: snackBarDataType;
    setSnackBarData: (data: snackBarDataType) => void;
    selectedNavItem: NavItem;
    setSelectedNavItem: (item: NavItem) => void;
}

export const commonSlice: StateCreator<CommonSlice> = (set) => ({
    componentHeading: "",
    selectedNavItem: {
        title: "Active Orders",
    },
    setSelectedNavItem: (selectedNavItem: NavItem) =>
        set(() => ({
            selectedNavItem,
        })),
    setComponentHeading: (text: string) =>
        set(() => ({
            componentHeading: text,
        })),
    snackBarData: {
        open: false,
        message: "",
        type: "success",
    },
    setSnackBarData: (data: snackBarDataType) =>
        set(() => ({
            snackBarData: data,
        })),
});
