import type { StateCreator } from "zustand";

export type UserType = {
    id: string;
    name: string;
    email: string;
};

export interface UserSlice {
    users: UserType[];
    addUser: (user: UserType) => void;
    removeUser: (id: string) => void;
    updateUser: (user: UserType) => void;
}

export const userSlice: StateCreator<UserSlice> = (set) => ({
    users: [],
    addUser: (user: UserType) =>
        set((state: UserSlice) => ({
            users: [user, ...state.users],
        })),
    removeUser: (id: string | number) =>
        set((state: UserSlice) => ({
            users: state.users.filter((user) => user.id !== id),
        })),
    updateUser: (user: UserType) =>
        set((state: UserSlice) => ({
            users: state.users.map((u) => {
                if (u.id === user.id) {
                    return {
                        ...u,
                        ...user,
                    };
                } else {
                    return u;
                }
            }),
        })),
});
