import { Box } from "@mui/material";

export default function Header(props: {
    children: React.ReactNode;
    bgColor?: string;
}) {
    return (
        <Box
            sx={{
                width: "100%",
                paddingTop: 0,
                pb: 2,
                marginTop: 0,
                paddingLeft: 8,
                background: props.bgColor,
                color: props.bgColor === "red" ? "#fff" : "#000",
            }}
        >
            {props.children}
        </Box>
    );
}
