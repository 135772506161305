import zukeeper from "zukeeper";
import { create } from "zustand";

import type { CommonSlice } from "./commonSlice";
import { commonSlice } from "./commonSlice";
import type { UserSlice } from "./userSlice";
import { userSlice } from "./userSlice";
const useCommonStore = create<CommonSlice>(zukeeper(commonSlice));
const useUserStore = create<UserSlice>(zukeeper(userSlice));

const myWindow: any = window;
myWindow.store = {
    useCommonStore,
    useUserStore,
};

export { useCommonStore, useUserStore };
