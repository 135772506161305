import CustomeButton from "@components/Button";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ReviewMenuConfirmation() {
    const navigate = useNavigate();
    return (
        <Typography
            component="div"
            sx={{
                textAlign: "center",
                "> p, div, h5": {
                    marginBottom: "30px",
                },
            }}
        >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Review your menu before going live
            </Typography>
            <Typography sx={{ color: "#a8a8a8" }}>
                If you have already reviewed your menu in merchant portal go
                live
            </Typography>

            <Typography component="div">
                <CustomeButton default variant="contained" sx={{ mr: 3 }}>
                    I Reviewed It
                </CustomeButton>
                <CustomeButton
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/manage-menu")}
                >
                    Review Menu
                </CustomeButton>
            </Typography>
        </Typography>
    );
}
