import CustomeButton from "@components/Button";
import Modal from "@components/Modal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    Box,
    Button,
    Divider,
    Grid2,
    IconButton,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function AddSpecialHoursModal(props: {
    showAddSpecialHoursModal: boolean;
    setshowAddSpecialHoursModal: any;
    onConfirm: () => void;
}) {
    const { showAddSpecialHoursModal, setshowAddSpecialHoursModal, onConfirm } =
        props;
    const [step, setStep] = useState<string>("Date Selection");

    return (
        <Modal
            title={<strong>Add Special Hours or Closures</strong>}
            open={showAddSpecialHoursModal}
            closeModal={() => {
                setshowAddSpecialHoursModal(false);
            }}
            maxWidth="md"
            fullScreen={false}
            content={
                <Box
                    sx={{
                        width: "100%",
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                        }}
                    >
                        Set your special hours below
                    </Typography>
                    <Grid2 container spacing={2} sx={{ mt: 3 }}>
                        <Grid2 size={3}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Dates
                            </Typography>
                        </Grid2>
                        <Grid2 size={4}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Store Open
                            </Typography>
                        </Grid2>
                        <Grid2 size={5}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}
                            >
                                Store Close
                            </Typography>
                        </Grid2>
                        <Divider sx={{ width: "100%" }} />
                        <Grid2 size={3}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    mt: 1,
                                }}
                            >
                                Date
                            </Typography>
                        </Grid2>
                        <Grid2 size={4}>
                            <input
                                type="time"
                                style={{
                                    width: "200px",
                                    paddingBlock: "10px",
                                    paddingInline: "10px",
                                    border: "2px solid lightgrey",
                                    borderRadius: "4px",
                                }}
                            />
                        </Grid2>
                        <Grid2
                            size={5}
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <input
                                type="time"
                                style={{
                                    width: "200px",
                                    paddingBlock: "10px",
                                    paddingInline: "10px",
                                    border: "2px solid lightgrey",
                                    borderRadius: "4px",
                                }}
                            />
                            <IconButton>
                                <AddCircleOutlineIcon
                                    sx={{ fontSize: 25, color: "#475EDC" }}
                                />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            gap: 2,
                            mt: 5,
                        }}
                    >
                        <CustomeButton
                            default
                            variant="contained"
                            sx={{ px: 3 }}
                            onClick={() => setshowAddSpecialHoursModal(false)}
                        >
                            Back
                        </CustomeButton>
                        <Button
                            sx={{ px: 3 }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onConfirm();
                                setshowAddSpecialHoursModal(false);
                            }}
                        >
                            Confirm Special Hours
                        </Button>
                    </Box>
                </Box>
            }
        />
    );
}
