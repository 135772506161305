import CustomeButton from "@components/Button";
import CustomTextfield from "@components/Textfield";
import { Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
    const theme = useTheme();
    const navigate = useNavigate();

    const onClickLogin = () => {
        navigate("/review-menu-confirmation");
    };

    return (
        <>
            <CustomTextfield
                fullWidth
                color="secondary"
                label="Enter Email Address or Phone Number"
            />
            <CustomTextfield fullWidth color="secondary" label="Password" />
            <CustomeButton
                sx={{ mt: 2 }}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onClickLogin}
            >
                Sign In
            </CustomeButton>
            <Typography
                component="div"
                color="secondary"
                sx={{
                    textAlign: "center",
                    mt: 2,
                    cursor: "pointer",
                    width: "100%",
                    a: {
                        color: theme.palette.secondary.dark,
                    },
                }}
            >
                <Link to="/register">Register here</Link>
            </Typography>
        </>
    );
}
