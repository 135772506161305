import CustomeButton from "@components/Button";
import Header from "@components/Header";
import {
    Assignment,
    Bolt,
    KeyboardArrowDown,
    Notifications,
    Print,
} from "@mui/icons-material";
import { Menu, MenuItem, Paper, Switch, Typography } from "@mui/material";
import { useState } from "react";

export default function Settings() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [volume, setVolume] = useState<string>("Quiet");
    const options = ["Quiet", "Loud"];
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Header>
                {" "}
                <Typography variant="h6" fontWeight={"bold"}>
                    Settings
                </Typography>
            </Header>
            <Typography
                component="div"
                sx={{
                    p: 3,
                    ".paper": {
                        borderRadius: "10px",
                        mt: 1,
                        "& > div:not(:last-child)": {
                            borderBottom: "2px solid #eee",
                        },
                        "& > div": {
                            p: 2,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            "& > div": {
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "bold",
                            },
                            svg: {
                                mr: 1,
                            },
                        },
                    },
                }}
            >
                <Typography variant="h5">Orders</Typography>
                <Paper className="paper">
                    <Typography component="div">
                        <Typography component="div">
                            <Bolt /> Auto-confirm new orders
                        </Typography>
                        <Typography component="div">
                            <Switch color="secondary" />
                        </Typography>
                    </Typography>
                    <Typography component="div">
                        <Typography component="div">
                            <Notifications /> New order alter volume
                        </Typography>
                        <Typography component="div">
                            <div>
                                <Typography
                                    component="div"
                                    display="flex"
                                    sx={{ cursor: "pointer" }}
                                >
                                    <Typography color="secondary">
                                        {volume}
                                    </Typography>
                                    <Typography
                                        component="div"
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                            open ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                            open ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <KeyboardArrowDown />
                                    </Typography>
                                </Typography>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    slotProps={{
                                        paper: {
                                            style: {
                                                width: "20ch",
                                            },
                                        },
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option}
                                            selected={option === volume}
                                            onClick={() => {
                                                setVolume(option);
                                                handleClose();
                                            }}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        </Typography>
                    </Typography>
                    <Typography component="div">
                        <Typography component="div">
                            <Assignment /> Create test order
                        </Typography>
                    </Typography>
                    <Typography component="div" borderBottom="2px solid #eee">
                        <Typography component="div">
                            <Bolt /> Alter when dasher is arriving
                        </Typography>
                        <Typography component="div">
                            <Switch color="secondary" defaultChecked />
                        </Typography>
                    </Typography>
                </Paper>
                <br />
                <Typography variant="h5">Printer</Typography>
                <Paper className="paper">
                    <Typography component="div">
                        <Typography component="div">
                            <Typography component="div">
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        display: "flex",
                                    }}
                                    component="div"
                                >
                                    <Print sx={{ mr: 1 }} /> Printer
                                </Typography>
                                <Typography
                                    color="secondary"
                                    sx={{ fontWeight: "bold", ml: 4 }}
                                >
                                    No printer setup
                                </Typography>
                            </Typography>
                        </Typography>
                        <Typography component="div">
                            <CustomeButton
                                color="secondary"
                                sx={{ textTransform: "none" }}
                            >
                                Setup printer
                            </CustomeButton>
                        </Typography>
                    </Typography>
                </Paper>
            </Typography>
        </>
    );
}
