import { Typography } from "@mui/material";
import Routes from "@routes/index";
import { askUserPermission } from "notificationManager";
import { useEffect } from "react";
import { subscribeUser } from "subscribeUser";

function App() {
    useEffect(() => {
        askUserPermission().then((permission) => {
            if (permission === "granted") {
                subscribeUser();
            }
        });
    }, []);

    return (
        <Typography component="div" sx={{ height: "100vh", margin: "auto" }}>
            <Routes />;
        </Typography>
    );
}

export default App;
