import Header from "@components/Header";
import Loader from "@components/Loader";
import { ArrowForwardIos, KeyboardArrowDown } from "@mui/icons-material";
import {
    Box,
    Grid2,
    Menu,
    MenuItem as MUIMenuItem,
    Typography,
} from "@mui/material";
import MenuItemDetails from "@pages/ManageMenu/MenuItemDetails";
import { getCategories } from "@services/categories";
import { getMenus } from "@services/menu";
import { useCommonStore } from "@store/index";
import { useEffect, useState } from "react";
import {
    CategoryType,
    MenuItem,
    MenuType,
    ResponseDataType,
} from "types/services";

export default function ManageMenuMenu() {
    const setSelectedNavItem = useCommonStore(
        (state) => state.setSelectedNavItem
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [menus, setMenus] = useState<MenuType[]>([]);
    const [selectedMenu, setSelectedMenu] = useState<MenuType>();
    const [selectedCategory, setSelectedCategory] = useState<CategoryType>();
    const [selectedItem, setSelectedItem] = useState<MenuItem>();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedMenuName, setSelectedMenuName] = useState<string>(
        selectedMenu?.name || ""
    );
    const [outOfStockItemsCount, setOutOfStockItemsCount] = useState<number>(2);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        try {
            setLoading(true);
            const restaurantId = "88fa0d87-a38c-4b63-a9f4-23749b79b74c";
            const categoriesPromiss = getCategories(restaurantId);
            const menusPromiss = getMenus(restaurantId);

            Promise.all([categoriesPromiss, menusPromiss])
                .then((values) => {
                    const [categoriesRes, menusRes] = values;
                    const categoriesData: ResponseDataType<CategoryType[]> =
                        categoriesRes.data;
                    if (categoriesData.success) {
                        setCategories(categoriesData.items);
                        setSelectedCategory(categoriesData.items[0]);
                    }

                    const menusData: ResponseDataType<MenuType[]> =
                        menusRes.data;
                    if (menusData.success) {
                        setMenus(menusData.items);
                        setSelectedMenu(menusData.items[0]);
                        setSelectedMenuName(menusData.items?.[0]?.name);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (err) {
            console.log("err", err);
            setLoading(false);
        }
    }, []);
    console.log(selectedMenu);

    const onClickItem = (item: MenuItem) => {
        setSelectedItem({
            ...item,
        });
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <Header>
                <Typography
                    component="div"
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography component="div">
                        <div>
                            <Typography
                                component="div"
                                display="flex"
                                sx={{ cursor: "pointer" }}
                            >
                                <Typography variant="h6" fontWeight={"bold"}>
                                    {selectedMenuName}
                                </Typography>
                                <Typography
                                    component="div"
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={
                                        open ? "long-menu" : undefined
                                    }
                                    aria-expanded={open ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <KeyboardArrowDown />
                                </Typography>
                            </Typography>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                slotProps={{
                                    paper: {
                                        style: {
                                            width: "20ch",
                                        },
                                    },
                                }}
                            >
                                {menus?.map((menu) => (
                                    <MUIMenuItem
                                        key={menu.id}
                                        selected={
                                            menu.name === selectedMenuName
                                        }
                                        onClick={() => {
                                            setSelectedMenuName(menu.name);
                                            handleClose();
                                        }}
                                    >
                                        {menu.name}
                                    </MUIMenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Typography>
                    <Box
                        component="section"
                        sx={{
                            py: "2px",
                            px: 1,
                            borderRadius: "4px",
                            border: "1px solid lightgrey",
                            mr: 1,
                        }}
                    >
                        <Typography sx={{ fontSize: 12 }} fontWeight={"bold"}>
                            Show Out of Stock Items ({outOfStockItemsCount})
                        </Typography>
                    </Box>
                </Typography>
            </Header>
            <Grid2 container>
                <Grid2 size={4}>
                    <Typography component="div">
                        <Typography
                            component="div"
                            sx={{
                                p: 1,
                                pl: 2,
                                borderLeft: "5px solid #edf2f3",
                                fontWeight: "bold",
                            }}
                        >
                            Categories
                        </Typography>
                        {categories.map((category, index) => (
                            <Typography
                                key={category.name}
                                component="div"
                                onClick={() => setSelectedCategory(category)}
                                sx={{
                                    p: 1,
                                    mb: 1,
                                    pl: 2,
                                    cursor: "pointer",
                                    width: "90%",
                                    borderLeft: "5px solid #edf2f3",
                                    ...(selectedCategory?.id === category.id
                                        ? {
                                              borderLeft: "5px solid #5f73c7",
                                              background: "#fff",
                                              borderTopRightRadius: "2px",
                                              borderBottomRightRadius: "2px",
                                          }
                                        : {}),
                                }}
                            >
                                {category.name}
                            </Typography>
                        ))}
                    </Typography>
                </Grid2>

                <Grid2 size={8}>
                    <Typography
                        component="div"
                        sx={{
                            mr: 1,
                            padding: "12px",
                            background: "#fff",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            display: "grid",
                            gridTemplateColumns: "9fr 1fr",
                            alignItems: "center",
                            marginBottom: "3px",
                        }}
                    >
                        <div>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {selectedCategory?.name}
                            </Typography>
                            <Typography
                                sx={{
                                    width: "100%",
                                    fontSize: "14px",
                                    color: "#bebebe",
                                }}
                            >
                                {selectedCategory?.description}
                            </Typography>
                        </div>
                        <Typography
                            component="span"
                            sx={{
                                width: "100%",
                                fontSize: "14px",
                                color: "#bebebe",
                            }}
                        >
                            {selectedCategory?.menu_items.length || "0"} items
                        </Typography>
                    </Typography>
                    {selectedCategory?.menu_items.map((item, index) => (
                        <Typography
                            key={item.name}
                            component="div"
                            onClick={() => {
                                onClickItem(item);
                                setShowModal(true);
                            }}
                            sx={{
                                mr: 1,
                                marginBottom: "3px",
                                padding: "12px",
                                background: "#fff",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                cursor: "pointer",
                                ...(index ===
                                selectedCategory.menu_items.length - 1
                                    ? {
                                          borderBottomLeftRadius: "10px",
                                          borderBottomRightRadius: "10px",
                                      }
                                    : {}),
                            }}
                        >
                            <Typography component="div">
                                <strong>{item.name}</strong>{" "}
                                <span style={{ color: "#bebebe" }}>
                                    ${item.price}
                                </span>
                            </Typography>
                            <ArrowForwardIos sx={{ fontSize: "14px" }} />
                        </Typography>
                    ))}
                </Grid2>
            </Grid2>
            {/* Selected Item Modal */}
            {selectedItem && (
                <MenuItemDetails
                    open={showModal}
                    closeModal={() => setShowModal(false)}
                    item={selectedItem}
                    fullScreen={true}
                    selectedMenuName={selectedCategory?.name || ""}
                />
            )}
        </>
    );
}
